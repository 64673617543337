let data = []

data = [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'User Management',
    icon: 'UsersIcon',
    children: [
      {
        title: 'User',
        route: 'user-mgmt',
      },
      {
        title: 'Tutor Verification',
        route: 'tutor-verification-mgmt',
      },
    ],
  },
  {
    title: 'Sessions Management',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'All Session(s)',
        route: 'all-session-mgmt',
      },
      {
        title: 'Request for Session',
        route: 'req-session-mgmt',
      },
    ],
  },
  {
    title: 'Sessions Booking Management',
    icon: 'PocketIcon',
    children: [
      {
        title: 'Cancellation Request(s)',
        route: 'cancellation-session-details',
      },
      {
        title: 'Cancelled Session(s)',
        route: 'canceled-session-mgmt',
      },
      {
        title: 'Pending Session(s)',
        route: 'pending-session-mgmt',
      },
      {
        title: 'Rejected Session(s)',
        route: 'rejected-session-mgmt',
      },
      {
        title: 'Current Online Session(s)',
        route: 'current-online-session-mgmt',
      },
      {
        title: 'Upcoming Session(s)',
        route: 'upcoming-session-mgmt',
      },
      {
        title: 'Past Session(s)',
        route: 'past-session-mgmt',
      },
    ],
  },
  {
    title: 'Complaint Management',
    icon: 'ListIcon',
    children: [
      {
        title: 'Complaint by Learner',
        route: 'complaint-learner-mgmt',
      },
      {
        title: 'Complaint by Tutor',
        route: 'complaint-tutor-mgmt',
      }
    ],
  }
  ,
  {
    title: 'Review Management',
        route: 'review-management-mgmt',
        icon:'MessageCircleIcon'    
  }
  ,
  {
    title: 'Banner Management',
    route: 'banner-management-mgmt',
    icon:'LayoutIcon'    
  },
  {
    title: 'Category Management',
    route: 'category-mgmt',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Curriculum Management',
    icon: 'BookIcon',
    children: [
      {
        title: 'Curriculum',
        route: 'syllabus-mgmt',
      },
      {
        title: 'Grade',
        route: 'grade-mgmt',
      }
    ],
  },
  {
    title: 'Transaction Management',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Withdraw Request',
        route: 'withdraw-request',
      },
      {
        title: 'All Transactions',
        route: 'transaction-history',
      }
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'School Management',
        route: 'school-mgmt',
      },
      {
        title: 'Email Template',
        route: 'email-template',
      },
      {
        title: 'Notification Management',
        route: 'notification-mgmt',
      },
      {
        title: 'Plan Management',
        route: 'plan-mgmt',
      },
      {
        title: 'Payment Geteway Management',
        route: 'paymentgateways',
      },
      {
        title: 'Terms and Conditions',
        route: 'terms&condition',
      },
      {
        title: 'Privacy Policy',
        route: 'privacypolicy',
      },
      {
        title: 'FAQ',
        route: 'faq',
      },
      {
        title: 'Contact Us',
        route: 'contact_us',
      },
      {
        title: 'General Settings',
        route: 'generalsettings',
      },
    ],
  },
]
export default data