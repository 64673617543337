<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >   
    <template #button-content>     
      <div class="d-sm-flex d-none user-nav">       
        <p class="user-name font-weight-bolder mb-0">
          {{ (userData && userData.fullName) ? userData.fullName : ((userData && userData.username) ? userData.username : "") }}
        </p>
        <span class="user-status">{{ (userData && userData.role) ? userData.role : ''  }}</span>
      </div>
      <b-avatar
        size="40"
        v-if="imageUrl==''"
        :src="(userData && userData.avatar) ? userData.avatar : '' "
        variant="dark"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
      <b-avatar
        v-else
        size="40"
        :src="imageUrl"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BButton 
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import firebase from "@/utils/firebaseInit";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton 
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      imageUrl:''
    }
  },
  created() {
    var self = this
    self.$root.$on('logoutUser',()=>{
      self.logout()
    })
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('jwtToken')
      this.$ability.update(initialAbility)
      localStorage.removeItem('currentUser')     
       firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: 'auth-login' 
          });
        });
    
    },
  },
  beforeDestroy() {
    this.$root.$off('logoutUser')
  }
}
</script>
