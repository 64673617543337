<template>
<div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
      style="z-index:999999;margin-left:-160px; margin-top:350px;"
    />
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <b-col class="content-header-left mb-2" cols="12" md="8">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                class="breadcrumb_home breadcrumb_home_icon"
                v-show="$route.meta.resource == undefined"
                to="/dashboard"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                class="breadcrumb_home breadcrumb_home_icon"
                v-show="$route.meta.resource == 'ACL'"
                to="/dashboard"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                class="breadcrumb_home breadcrumb_home_icon"
                v-show="$route.meta.resource == 'EAC'"
                to="/emp-dashboard"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="
                  item.text == 'Application Mgmt' &&
                  $route.meta.resource == 'EAC'
                    ? true
                    : item.active
                "
                :to="item.to"
              >
                <span v-if="item.text == 'tutor_name'">
                  <span @click="$router.replace({path:'/user-management/users/'+$route.params.id})">{{ $route.params.name }}</span>
                </span>
                <span v-else-if="item.text == 'user_name'">
                  <router-link :to="{name:'user-details',params:{
                    name:$route.params.name,
                    role:$route.params.role,
                    id:$route.params.id
                  }}">{{ $route.params.name }}</router-link>
                </span>
                <span v-else>
                  {{ item.text }}
                </span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'User Details' || $route.meta.pageTitle == 'Learner Details'"
                active
              >
                <span> {{ userName ? userName : "" }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Tutor Verification Details'"
                active
              >
                <span> {{ tutorVerificationTutorName ? tutorVerificationTutorName : "" }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Request for Session Detail'"
                active
              >
                <span> {{ $route.params.id }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Session Details'"
                active
              >
                <span> {{ allSessionDetailTitle }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Current Online Details'"
                active
              >
                <span> {{ bookingIdForCurrent }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Edit User'"
                
              >
                <router-link v-if="editUserRole.includes('learner')"  :to="`/user-management/users/${$route.params.id}`"> {{editUserName}}</router-link>
                <router-link v-else-if="editUserRole.includes('tutor')"  :to="`/user-management/users/${$route.params.id}`"> {{editUserName}}</router-link>

              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Edit User'"
                active
              >
                <span>Edit User</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Edit Tutor'"
                
              >
              <router-link v-if="editUserRole.includes('learner')"  :to="`/user-management/users/${$route.params.id}`"> {{editUserName}}</router-link>
                <router-link v-else-if="editUserRole.includes('tutor')"  :to="`/user-management/users/${$route.params.id}`"> {{editUserName}}</router-link>

              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Edit Tutor'"
                active
              >
                <span>Edit User</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Upcoming Session Details'"
                active
              >
                <span> {{ bookingIdForUpcoming }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Rejected Session Details'"
                active
              >
                <span> {{ transectionBookingDetailId }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Pending Session Details'"
                active
              >
                <span> {{ transectionBookingDetailId }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Transaction Booking Details'"
                active
              >
                <span> {{ transectionBookingDetailId }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Complaint Booking Details'"
                active
              >
                <span> {{ transectionBookingDetailId }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Cancelled Session Details'"
                active
              >
                <span> {{ bookingId }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Cancellation Request Details'"
                active
              >
                <span> {{ bookingSessionId }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Category Details'"
                active
              >
                <span> {{ categoryName }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Upcoming Details'"
                active
              >
                <span> {{ $route.params.id }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Past Session Details'"
                active
              >
                <span> {{ bookingIdForPast }}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.pageTitle == 'Canceled Session Details'"
                active
              >
                <span> {{ $route.params.id }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="content-header-right text-md-right mb-1" md="4" cols="12">
      <div>
        <div v-if="$route.meta.pageTitle == 'User'" 
        class="top_btn_right">
          <b-button
            variant="primary"
            :to="{
              name: 'create-user',
            }"
            @click="isSpinner = false"
          >
            Create User
          </b-button>
        </div>
        <div
          v-else-if="$route.meta.pageTitle == 'User Details'"
          class="top_btn_right"
        >
          <div class="user_checkbox d-inline" v-if="userRole.includes('tutor') &&userRole.includes('learner')">
            <a class="p-0">Learner</a>
            <b-form-checkbox :checked="false" v-model="tutorDetailSwitch" name="active" switch inline @change="updateSwitchUser()">
            </b-form-checkbox>
            <a class="p-0 mr-2">Tutor</a>              
          </div>  
          <b-button
            variant="outline-primary"
            v-b-modal.modal-add-credit
            :disabled="userStatus == 4"
            class="d-inline-block align-top mr-2 add_child_btn"
            v-if="(tutorDetailSwitch == false)&&(isUserChild == false)"
            id="toggle-btn"
            @click="errorMessageForCredit=''"
          >
            Add Credit
          </b-button>
          <b-modal
            id="modal-add-credit"
            ref="my-modal"
            title="Add Credit"
            ok-title="Add"
            cancel-title="Cancel"
            cancel-variant="outline-primary"
            centered
            data-class="addcredit_popup"
            @show="CreditresetModal"
            @hidden="CreditresetModal"
            @ok="CreditHandleSubmit"
            :ok-disabled="isSpinner"
            :cancel-disabled="isSpinner"
            @close="closeFireCredit()"
          >
            <validation-observer ref="creditForm">
            <form ref="form">
              <validation-provider
                #default="{ errors }"
                name="credit"
                :rules="{
                max_value: 100,
                min_value: 0,
                }"
              >
                <b-form-group> 
                  <label for="name-input">No. of Credits<span style="color: red;">*</span></label>
                  <b-form-input
                    id="name-input"
                    v-model.trim="credit"
                    @blur="credit = credit.trim()"
                    placeholder="Enter Number"
                    @keypress="isCharAt($event)"
                    autocomplete="off"
                    :maxlength="3" 
                    required
                  />
                  <small class="text-danger" style="display: block">{{errors[0]}}</small>
                  <small class="text-danger" style="color: red;" v-if="errorMessageForCredit!='' && errors[0] != 0">{{errorMessageForCredit}}</small>
                </b-form-group>
              </validation-provider>
            </form>
            </validation-observer>
          </b-modal>    
          <b-button  v-if="!userRole.includes('tutor') && userRole.includes('learner')"
          variant="primary" 
          class="btn-icon" 
          :disabled="userStatus == 4"
          :to="{
            name: 'edit-user',  
            params: { 
              name: $route.params.name, 
              id: $route.params.id 
              },
          }"
          @click="isSpinner = false"
          >
            Edit
          </b-button>      
          <b-button  v-else
          variant="primary" 
          :disabled="userStatus == 4"
          class="btn-icon" 
          :to="{
            name: 'edit-tutor-user',  
            params: { 
              name: $route.params.name, 
              id: $route.params.id 
              },
          }"
          @click="isSpinner = false"
          >
            Edit
          </b-button>   
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Request for Session Detail'"
          class="requestdetail_right_btn"
        >
          <b-button
            variant="success"
            class="mr-2"
            @click="$root.$emit('approveSession')"
          >
            Approve
          </b-button>
          <b-button variant="danger"    @click="$root.$emit('rejectSession')">
            Decline
          </b-button>
        </div>

        <div v-else-if="$route.meta.pageTitle == 'Pending Session Details'"
          class="requestdetail_right_btn"
        >
          <b-button
            variant="outline-danger"
            class="mr-2"
            v-b-modal.modal-reject-detail-booking
          >
            Reject
          </b-button>
          <b-button
            variant="success"
            class="mr-2"
            @click="ConfirmBooking()"
          >
            Confirm
          </b-button>
           <b-modal
              id="modal-reject-detail-booking"
              ref="my-modal"
              title="Rejection Reason"
              ok-title="Reject"
              data-class="addcredit_popup"
              data-value-class="addcredit_popup"
              centered
              cancel-variant="outline-secondary"
              @show="rejectbookingresetModal"
              @hidden="rejectbookingresetModal"
              @ok="rejectbookinghandleOk"
            >
            <validation-observer ref="formCancel">
              <b-form ref="form" @submit.stop.prevent="cancelbookinghandleSubmit">
                  <b-form-group class="radioselected" label-for="usertype">
                    <b-form-radio
                      v-model="RadioSelectedReject"
                      name="some-radios"
                      value="Not interested in this Session"
                    >
                      Not interested in this Session
                    </b-form-radio>
                    <b-form-radio
                      v-model="RadioSelectedReject"
                      name="some-radios"
                      value="Not available"
                    >
                      Not available
                    </b-form-radio>
                    <b-form-radio
                      v-model="RadioSelectedReject"
                      name="some-radios"
                      value="Medical Emergency"
                    >
                      Medical Emergency
                    </b-form-radio>
                    <b-form-radio
                      v-model="RadioSelectedReject"
                      name="some-radios"
                      value="Family situation"
                    >
                      Family situation
                    </b-form-radio>
                    <b-form-radio
                      v-model="RadioSelectedReject"
                      name="some-radios"
                      value="Other"
                    >
                      Other
                    </b-form-radio>
                  </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="reason for rejection"
                  rules="required|min:3|max:300"
                  v-if="RadioSelectedReject === 'Other'"
                >
                  <b-form-group
                    :state="nameState"
                    label-for="name-input"
                    invalid-feedback="Reason for Rejection is required"
                    v-if="RadioSelectedReject === 'Other'"
                  >
                  <label style="font-weight:600;">Reason for Rejection</label>
                    <b-form-textarea
                      id="name-input"
                      v-model="rejectReason"
                      :state="nameState"
                      placeholder="Enter Reason For Rejection"
                      required
                    />
                    <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </b-modal>
        </div>

        <div v-else-if="$route.meta.pageTitle == 'Session Details'"
          class="requestdetail_right_btn"
        >
          <b-button variant="outline-danger" @click="$root.$emit('disable-session')" v-if="sessionStatus == 2">
            Disable Session
          </b-button>
           <b-button variant="outline-success" @click="$root.$emit('active-session')" v-if="sessionStatus == 3">
            Active Session
          </b-button>
        </div>

        <div v-else-if="$route.meta.pageTitle == 'Banner Management'">
            <b-button @click="OpenAddBanner()"
              variant="primary"
              v-b-modal.modal-banner
            >
              Upload New Banner
            </b-button>

            <b-modal
              id="modal-banner"
              :title="bannerDynemicTitle"
              v-model="bannerModel"
              :ok-title="bannerDynemicButton"
              cancel-variant="outline-secondary"
              centered
              data-class="addcredit_popup"
              :no-close-on-backdrop="true"
              @show="bannerresetModal"
              @hidden="bannerresetModal"
              @ok="bannerhandleOk"
              :ok-disabled="isSpinner"
              :cancel-disabled="isSpinner"
              @close="closeFire()"
            >
              <validation-observer ref="addBannerForm">
                <b-form ref="form" @submit.stop.prevent="bannerhandleSubmit">
                  <validation-provider
                    #default="{ errors }"
                    name="user type"
                    rules="required"
                  >
                    <b-form-group
                      label="User Type"
                      label-for="usertype"
                      class="banner-checkbox"
                    >
                      <b-form-checkbox value="learner" v-model="bannerUserTypes" :disabled="isSpinner" class="custom-control-primary">
                        Learner
                      </b-form-checkbox>
                      <b-form-checkbox
                        value="tutor" v-model="bannerUserTypes" :disabled="isSpinner" class="custom-control-primary">
                        Tutor
                      </b-form-checkbox>
                      <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <b-form-group label="Upload File" label-for="uploadfile">
                    <validation-provider
                      #default="{ errors }"
                      name="banner image"
                      rules="required"
                    >
                      <b-form-file v-model="file" id="bannerImagePicker" :class="{'hidePlaceHolderOfInput':isEditClass}" :disabled="isSpinner" class="mt-1"  plain @change="checkBannerFile"/><div v-if="isEditPopup && ImageFile!=''">{{ImageFile}}</div>
                      <small class="text-danger" v-if="ImageFile == ''">{{errors[0]}}</small>
                      <small class="text-danger" v-if="!isBannerImage">Allowed JPG, or PNG. Max size of 200 KB and W 394 x H 153 px</small>
                      <small class="text-danger" v-if="bannerRequiredImafge != ''">{{bannerRequiredImafge}}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
            </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Category'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            v-b-modal.modal-create-category
            @click="openCategoryCreate()"
          >
            Create Category
          </b-button>

        </div>
        <div v-else-if="$route.meta.pageTitle == 'Category Details'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="btn-icon"
            @click="editPopup()"
          >
            Edit
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Grade'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            v-b-modal.modal-create-Grade
            @click="editGrade=false,openGradeCreate()"
          >
            Add New Grade
          </b-button>
          <b-modal
            id="modal-create-Grade"
            ref="my-modal"
            :title="gradePopupTitle"
            :ok-title="GradeDynemicButton"
            cancel-variant="outline-primary"
            centered
            data-class="addcredit_popup"
            @show="GraderesetModal"
            @hidden="GraderesetModal"
            @ok="GradehandleSubmit"
            :ok-disabled="isSpinner"
            :cancel-disabled="isSpinner"
          >
          <validation-observer ref="Gradeform">
            <form class="subdropdown" ref="form" @submit.stop.prevent="GradehandleSubmit">
              <validation-provider
                    #default="{ errors }"
                    name="grade name"
                    rules="required|min:3"
                >
              <b-form-group
                :state="nameState"
                label="Grade Name"
                label-for="name-input"
                invalid-feedback="Grade name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="name"
                  placeholder="Enter Grade Name"
                  :state="nameState"
                  autocomplete="off"
                  @blur="name=name.trim()"
                  :maxlength=20
                  required
                />
                <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                <small class="text-danger"  v-if="sameGradeErrorMessage != '' && name != ''">{{sameGradeErrorMessage}}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                    #default="{ errors }"
                    name="curriculum name"
                    rules="required"
                  >
              <b-form-group
                :state="nameState"
                label="Select Curriculum"
                label-for="name-input"
                invalid-feedback="Category ame is required"
              >
                <b-form-select
                  v-model="curriculamselected"
                  :options="curriculamoption"
                />
                <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                
              </b-form-group>
              </validation-provider>
            </form>
          </validation-observer>
          </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'School Management'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            v-b-modal.modal-new-school
            id="toggle-btn"
            @click="openSchoolCreate()"
          >
            Add New School
          </b-button>
          <b-modal
            id="modal-new-school"
            ref="my-modal"
            :title="schoolTitle"
            :ok-title="schoolDynemicButton"
            cancel-variant="outline-primary"
            centered
            data-class="addcredit_popup"
            @show="schoolresetModal"
            @hidden="schoolresetModal"
            @ok="schoolhandleSubmit"
            :ok-disabled="isSpinner"
            :cancel-disabled="isSpinner"
          >
          <validation-observer ref="Schoolform">
            <form ref="form" @submit.stop.prevent="schoolhandleSubmit">
               <validation-provider
                  #default="{ errors }"
                  name="school name"
                  rules="required|min:3"
                >
              <b-form-group
                :state="nameState"
                label="School Name"
                label-for="name-input"
                invalid-feedback="School Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model.trim="name"
                  placeholder="Enter School Name"
                  :state="nameState"
                  maxlength="30"
                  @keyup="CheckSchoolNameExit()"
                  autocomplete="off"
                  @blur="name=name.trim()"
                  required
                />
                <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                <small class="text-danger"  v-if="sameSchoolErrorMessage != '' && name != ''">{{sameSchoolErrorMessage}}</small>
              </b-form-group>
              </validation-provider>
            </form>
          </validation-observer>
          </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Curriculum'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            v-b-modal.modal-new-curriculum
            id="toggle-btn"
            @click="openSyllabusCreate()"
            
          >
            Add New Curriculum
          </b-button>
          <b-modal
            id="modal-new-curriculum"
            ref="my-modal"
            :title="syllabusTitle"
            :ok-title="syllabusDynemicButton"
            cancel-variant="outline-primary"
            centered
            data-class="addcredit_popup"
            @show="syllabusresetModal"
            @hidden="syllabusresetModal"
            @ok="syllabushandleSubmit"
          >
          <validation-observer ref="Curriculumform">
            <form ref="form" @submit.stop.prevent="syllabushandleSubmit">
               <validation-provider
                  #default="{ errors }"
                  name="curriculum name"
                  rules="required|min:3"
                >
              <b-form-group
                :state="nameState"
                label="Curriculum Name"
                label-for="name-input"
                invalid-feedback="Curriculum Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model.trim="name"
                  placeholder="Enter Curriculum Name "
                  maxlength="30"
                  @keyup="CheckCurriculumNameExit()"
                  autocomplete="off"
                  @blur="name=name.trim()"
                  :state="nameState"
                  required
                />
              <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
              <small class="text-danger" v-if="sameCurriculumErrorMessage != '' && name != ''">{{sameCurriculumErrorMessage}}</small>
              </b-form-group>
              </validation-provider>
            </form>
         </validation-observer>
          </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Email Template'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="btn-icon"
            :to="{
              name: 'create-email-template',
              params: { name: $route.params.name, id: $route.params.id },
            }"
          >
            Create Email
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Edit Email Template'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            @click="CancelEmailData()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            @click="$root.$emit('createEmailTemplate'),isButton = true"
            :disabled="isButton"
          >
            <span v-if="isButton">
                <span>Update</span>
            </span>
            <span v-else>Update</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Create Email Template'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            @click="CancelEmailData()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            @click="$root.$emit('createEmailTemplate'),isButton = true"
            :disabled="isButton"
          >
            <span v-if="isButton">
                <span>Save</span>
            </span>
            <span v-else>Save</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Create User'" class="top_btn_right cancel_hover">
         ` <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            :to="{
              name:'user-mgmt'
            }"
            :disabled='isSpinner'
            @click="isSpinner = false"
          >          
          <span>Cancel</span>            
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled='isSpinner'
            @click="$root.$emit('saveUsers'),isSpinner = true"
          >
          <span>Save</span>
          </b-button>`
        </div>
        <div
          v-else-if="$route.meta.pageTitle == 'Edit User'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            :to="{
              name:'user-mgmt'
            }"
            :disabled='isSpinner || editUserStatus == 4'
            @click="isSpinner = false"
          >
            <span>Cancel</span>  
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled='isSpinner || editUserStatus == 4'
            @click='$root.$emit("editSimpleUser"),isSpinner = true'
          >
            <span>Update</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Edit Tutor'" class="top_btn_right cancel_hover">
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            :to="{
              name:'user-mgmt'
            }"
            :disabled='isSpinner'
            @click="isSpinner = false"
          >
            <span>Cancel</span>  
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled='isSpinner'
           @click='$root.$emit("editTutorData"),isSpinner = true'
          >
            <span>Update</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Edit Bank Account'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
          >
            Update
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Edit Qualification Document'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
          >
            Save
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Edit Identity Document'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
          >
            Save
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Notification Management'">
          <b-button
            variant="primary"
            v-b-modal.modal-notification
          >
            Send Notification
          </b-button>
          <b-modal
              id="modal-notification"
              title="Send Notification"
              ok-title="Send"
              cancel-variant="outline-secondary"
              centered
              data-class="addcredit_popup"
              :no-close-on-backdrop="true"
              @show="notificationresetModal"
              @hidden="notificationresetModal"
              @ok="notificationhandleOk"
              :ok-disabled="isSpinner"
              :cancel-disabled="isSpinner"
         >
              <validation-observer ref="notificationForm">
                <b-form
                  ref="form"
                  @submit.stop.prevent="notificationhandleSubmit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="user type"
                    rules="required"
                  >
                    <b-form-group label="User Type" label-for="usertype" class="usertypemargin">
                      <div class="usertype_checkbox">
                        <b-form-radio-group 
                          id="notificationRadio"
                          v-model="selectedNotificationUser"
                          :options="userTypeOption"
                          name="some-radios5"
                          >
                        </b-form-radio-group>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <b-form-group class="sendmessage" label="Message" label-for="message">
                    <validation-provider
                      #default="{ errors }"
                      name="message"
                      rules="required|min:3"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        placeholder="Enter Message"
                        rows="3"
                        name="Message"
                        v-model="notificationMessage"
                        maxlength = 280
                      />
                      <small class="text-danger" style="display: block">{{
                        errors[0]
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>
            </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Plan Management'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="btn-icon"
            :to="{
              name: 'create-plan',
              params: { name: $route.params.name, id: $route.params.id },
            }"
          >
            Add New Plan
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Create Plan'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            @click="$router.replace('/settings/plan-management'),isSpinner = false"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled="isSpinner"
            @click="SavePlanData(),isSpinner = true"
          >
            <span v-if="isSpinner">
                <span>Save</span>
            </span>
            <span v-else>Save</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Edit Plan'"
          class="top_btn_right cancel_hover"
        >
          <b-button
            variant="outline-primary"
            class="d-inline-block align-top mr-2 add_child_btn"
            @click="$router.replace('/settings/plan-management'),isSpinner = false"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled="isSpinner"
            @click="EditPlanData(),isSpinner=true"
          >
          <span v-if="isSpinner">
                <span>Update</span>
            </span>
            <span v-else>Update</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Terms and Conditions'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled='isButton'
            @click="$root.$emit('updateTNC'),isButton = true"
          >
            <span>Save</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Privacy Policy'"
          class="top_btn_right"
        >
          <b-button
            variant="primary"
            class="btn-icon"
            :disabled='isButton'
            @click="$root.$emit('UpdatePrivacyPolicy'),isButton = true"
          >
            <span>Save</span>
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Upcoming Session Details'" class="cancel_hover">
          <b-button
            variant="outline-danger"
            v-b-modal.modal-cancel-booking
            :disabled="isButton"
            
          >
            Cancel Booking
          </b-button>
          <b-modal
            id="modal-cancel-booking"
            ref="my-modal"
            title="Cancellation Reason"
            ok-title="Booking Cancel"
            data-class="addcredit_popup"
            data-value-class="addcredit_popup"
            centered
            cancel-variant="outline-secondary"
            @show="cancelbookingresetModal"
            @hidden="cancelbookingresetModal"
            @ok="cancelbookinghandleOk"
          >
          <validation-observer ref="formCancel">
            <b-form ref="form" @submit.stop.prevent="cancelbookinghandleSubmit">
                <b-form-group class="radioselected" label-for="usertype">
                  <b-form-radio
                    v-model="RadioSelected"
                    name="some-radios"
                    value="Not interested in this Session"
                  >
                    Not interested in this Session
                  </b-form-radio>
                  <b-form-radio
                    v-model="RadioSelected"
                    name="some-radios"
                    value="Not available"
                  >
                    Not available
                  </b-form-radio>
                  <b-form-radio
                    v-model="RadioSelected"
                    name="some-radios"
                    value="Medical Emergency"
                  >
                    Medical Emergency
                  </b-form-radio>
                  <b-form-radio
                    v-model="RadioSelected"
                    name="some-radios"
                    value="Family situation"
                  >
                    Family situation
                  </b-form-radio>
                  <b-form-radio
                    v-model="RadioSelected"
                    name="some-radios"
                    value="Other"
                  >
                    Other
                  </b-form-radio>
                </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="reason for cancellation"
                rules="required|min:3|max:300"
                v-if="RadioSelected === 'Other'"
              >
                <b-form-group
                  :state="nameState"
                  label-for="name-input"
                  invalid-feedback="Reason for Cancellation is required"
                  v-if="RadioSelected === 'Other'"
                >
                <label style="font-weight:600;">Reason for Cancellation</label>
                  <b-form-textarea
                    id="name-input"
                    v-model="name"
                    :state="nameState"
                    placeholder="Enter Reason For Cancellation"
                    required
                  />
                  <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>
          </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Cancellation Request Details' && this.tutorStatus !== 2" class="cancel_hover">
          <b-button
            variant="danger"
            class="mr-2"
            v-on:click="showSuspendtutor($route.params.id)"
            :disabled="isButton"
          >
            Suspend Tutor
          </b-button>
          <b-button
            variant="outline-primary"
            class="mr-2"
            v-on:click="showGivewarning($route.params.id)"
            :disabled="isButton"
            v-show="totalNoOfWarningOfTutor < totalWarnForSuspend"
          >
            Give Warning
          </b-button>
          <b-button
            variant="success"
            class="mr-2"
            v-on:click="showApprove($route.params.id)"
            :disabled="isButton"
          >
            Approve
          </b-button>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'Contact us'" 
        class="top_btn_right">
      <b-button
        variant="primary"
        class="d-inline-block align-top mr-2 add_child_btn"
        v-b-modal.modal-contact_us
        id="toggle-btn"
        @click="openContactUsForm"
        :disabled="isButton"
      >
        Add New Contact Us 
      </b-button>
      <b-modal
        id="modal-contact_us"
        ref="my-modal"
        :title="contactUsTitle"
        :ok-title="contactUsDynemicButton"
        cancel-variant="outline-primary"
        centered
        data-class="addcredit_popup"
        @show="contactUSresetModal"
        @hidden="contactUSresetModal"
        @ok="contactUshandleOk"
        :ok-disabled="isSpinner"
        :cancel-disabled="isSpinner"
      >
        <validation-observer ref="contactUsForm">
          <form ref="form" @submit.stop.prevent="contactUshandleSubmit">
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required|min:3|max:100"
            >
              <b-form-group
              style="font-weight: bold;"
                :state="nameState"
                label-for="name-input"
              > <label for="name-input">Title<span style="color: red;">*</span></label>
                <b-form-input
                  id="name-input"
                  v-model.trim="contactUSFormData.title"
                  @keyup="CheckInArrayContactUs()"
                  placeholder="Enter Title"
                  :state="nameState"
                  :maxlength="101"
                  autocomplete="off"
                  required
                />
                <small class="text-danger" style="display: block">{{
                  errors[0]
                }}</small>
                <small class="text-danger" style="display: block" v-if="contactUSErrorMessage != '' && contactUSFormData.title != '' && iscontactUsEdit === false">
                  {{ contactUSErrorMessage }}
                </small>
                <small class="text-danger" style="display: block" v-if="contactUSErrorMessageForEdit != '' && contactUSFormData.title != '' && iscontactUsEdit === true">
                  {{ contactUSErrorMessageForEdit }}
                </small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required|min:25|max:500"
            >
              <b-form-group style="font-weight: bold;" label-for="textarea-default">
                <label for="textarea-default">Description<span style="color: red;">*</span></label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Enter Description"
                  v-model.trim="contactUSFormData.description"
                  @keyup="CheckValidQuestionContactUs()"
                  no-resize
                  :state="nameState"
                  autocomplete="off"
                  rows="3"
                  :maxlength="501"
                  required
                />
                <small class="text-danger" style="display: block">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </form>
        </validation-observer>
      </b-modal>
        </div>
        <div v-else-if="$route.meta.pageTitle == 'FAQ'" 
        class="top_btn_right">
      <b-button
        variant="primary"
        class="d-inline-block align-top mr-2 add_child_btn"
        v-b-modal.modal-faq
        id="toggle-btn"
        @click="openFaqForm"
        :disabled="isButton"
      >
        Add New FAQ 
      </b-button>
      <b-modal
        id="modal-faq"
        ref="my-modal"
        :title="faqPopupTitle"
        :ok-title="faqDynemicButton"
        cancel-variant="outline-primary"
        centered
        data-class="addcredit_popup"
        @show="faqresetModal"
        @hidden="faqresetModal"
        @ok="faqhandleOk"
        :ok-disabled="isSpinner"
        :cancel-disabled="isSpinner"
      >
        <validation-observer ref="faqForm">
          <form ref="form" @submit.stop.prevent="faqhandleSubmit">
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required|min:3|max:100"
            >
              <b-form-group
              style="font-weight: bold;"
                :state="nameState"
                label-for="name-input"
              > <label for="name-input">Title<span style="color: red;">*</span></label>
                <b-form-input
                  id="name-input"
                  v-model.trim="faqFormData.title"
                  @keyup="CheckInArray()"
                  placeholder="Enter Title"
                  :state="nameState"
                  :maxlength="101"
                  autocomplete="off"
                  required
                />
                <small class="text-danger" style="display: block">{{
                  errors[0]
                }}</small>
                <small class="text-danger" style="display: block" v-if="faqErrorMessage != '' && faqFormData.title != '' && isFaqEdit === false">
                  {{ faqErrorMessage }}
                </small>
                <small class="text-danger" style="display: block" v-if="faqErrorMessageForEdit != '' && faqFormData.title != '' && isFaqEdit === true">
                  {{ faqErrorMessageForEdit }}
                </small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required|min:25|max:500"
            >
              <b-form-group style="font-weight: bold;" label-for="textarea-default">
                <label for="textarea-default">Description<span style="color: red;">*</span></label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Enter Description"
                  v-model.trim="faqFormData.description"
                  @keyup="CheckValidQuestion()"
                  no-resize
                  :state="nameState"
                  autocomplete="off"
                  rows="3"
                  :maxlength="501"
                  required
                />
                <small class="text-danger" style="display: block">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </form>
        </validation-observer>
      </b-modal>
        </div>
     
      </div>
      <div
        v-if="$route.meta.pageTitle == 'Tutor Verification Details'"
        class="tutor_top_right_btn"
      >
            <b-button
              v-if="getTutorStatus !== 'Verified'"
              variant="success"        
              class="modal-center"
              :disabled='isSpinner'
              @click="$root.$emit('openTutorVerificationModal')"
            >  
                <span>Verify</span> 
            </b-button>
            <b-button 
            v-if="getTutorStatus !== 'Reject'"
            variant="danger" 
            :disabled='isSpinner' 
            @click="openConfirmationModel('Reject')"
            >
                <span>Reject</span>          
            </b-button>
      </div>    
    </b-col>
  </b-row>
  <b-modal
  id="modal-create-category"
  ref="my-modal"
  :title="categoryPopupTitle"
  :ok-title="CategoryDynemicButton"
  cancel-variant="outline-primary"
  centered
  data-class="addcredit_popup"
  @hidden="CategoryresetModal"
  @ok="CategoryhandleSubmit"
  :ok-disabled="isSpinner"
  :cancel-disabled="isSpinner"
>
  <validation-observer ref="Categoryform">
  <form ref="form" @submit.stop.prevent="CategoryhandleSubmit">
      <validation-provider
          #default="{ errors }"
          name="category name"
          rules="required|min:3"
        >
      <b-form-group
      :state="nameState"
      label="Category Name"
      label-for="name-input"
      invalid-feedback="Category name is required"
      >
      <b-form-input
        
        id="name-input"
        v-model="editCategoryName"
        placeholder="Enter Category Name"
        :state="nameState"
        @keyup="CheckCategoryNameExist()"
        autocomplete="off"
        @blur="editCategoryName=editCategoryName.trim()"
        :maxlength=20
        required
      />
      <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
      <small class="text-danger" v-if="sameCategoryErrorMessage != '' && name != ''">{{sameCategoryErrorMessage}}</small>
      </b-form-group>
       <div class="card banner_top_section">
        <div>
          Icon parameters for uploading must be
          <strong>W 512 x H 512 px</strong>, and file size should not exceed
          <strong>200KB</strong>, file formats supported for
          <strong>jpg</strong> and <strong>png</strong>.
        </div>
      </div>
        <validation-provider
                      #default="{ errors }"
                      name="icon"
                      rules="required"
            >
         <b-form-group label="Icon" label-for="uploadfile">
        <b-form-file v-model="fileCategory" id="categoryImagePicker" :class="{'hidePlaceHolderOfInput':editCate}"  class="mt-1"  plain @change="checkCategoryFile"/><div v-if="editCate && categoryImageFile!=''">{{categoryImageFile}}</div>
        <small class="text-danger" v-if="categoryImageFile == ''">{{errors[0]}}</small>
        <small class="text-danger" v-if="!isCategoryImage">Allowed JPG, or PNG. Max size of 200 KB and W 512 x H 512 px</small>
        <small class="text-danger" v-if="categoryRequiredImage != ''">{{categoryRequiredImage}}</small>
         </b-form-group>
        </validation-provider>
      </validation-provider>
  </form>
  </validation-observer>
</b-modal>
</div>
</template>

<script>
import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import functionForSuspendtionAction from '@/utils/globalFunction/suspendActionFun.js';
import functionForSuspendOrBlockChild from '@/utils/globalFunction/suspendChildFunction'
import functionForGiveWarning from '@/utils/globalFunction/giveWarningFun.js';
import { required } from '@validations';
import { errorEmail } from '@/utils/apiRequests';
import {sendNotification} from './service.js'
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BFormRadio,
  BFormRadioGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import firebase from "firebase"
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as storageFirebase from "@/utils/FirebaseQueries/storageQuery/storageQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries.js";
const db = firebase.firestore()
import {dbCollections} from "@/utils/firebaseCollection.js"
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from 'axios';
import { async } from 'vue-phone-number-input/dist/vue-phone-number-input.common';
import { adminBookingNotification } from '../../../views/SessionsManagement/PendingSession(s)/service.js';
import {onWarningGiveNotification} from '@/views/ComplaintManagement/serviceWarningNotification.js'
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    BFormCheckbox,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,
    ValidationProvider, ValidationObserver,
  },
   data() {
    return {
      learnerDetailSwitch : false,
      tutorDetailSwitch : false,
      isSpinner: false,
      isUpdating:false,
      selected: "Valuable",
      name: null,
      nameState: null,
      option: ["Valuable", "Elite", "Normal", "N/A"],
      submittedNames: [],
      selected: ["a", "c"],
      file: null,
      fileCategory: null,
      Categoryselected: null,
      bannerModel:false,
      bannerDynemicTitle:'Upload New Banner',
      bannerDynemicButton:'Save',
      model: {
        active: [],
      },
      RadioSelected: "Not interested in this Session",
      userdetailchecked: false,
      userdetailindeterminate: false,
      isBannerImage:true,
      isCategoryImage: true,
      bannerImageFile:{},
      bannerTempArray:[],
      bannerUserTypes:[],
      bannerTempFile:{},
      isEditPopup:false,
      tempArray:[],
      selectedNotificationUser:"all",
      userTypeOption:[
        {text: 'All (Learner/Tutor)', value: 'all'},
        {text: 'Only Tutor', value: 'tutor'}
      ],
      ImageFile:'',
      categoryImageFile:'',
      isEditCategoryPopup: false,
      editId:'',
      isEditClass:false,
      bannerViewModel:false,
      viewId :'',
      sameCategoryErrorMessage : '',
      required,
      isInArray:false,
      categoryPopupTitle:'Create Category',
      editCategory:false,
      CategoryDynemicButton:'Save',
      editCategoryId:'',
      subCatMatchinIndex:'',

      schoolTitle:'Create New School',
      schoolDynemicButton:"Save",
      schoolId:"",
      isSchoolEdit:false,
      sameSchoolErrorMessage:'',
      sameSchool:false,
      syllabusTitle:'Create New Curriculum',
      syllabusDynemicButton:"Save",
      syllabusId:"",
      isSyllabusEdit:false,
      sameCurriculumErrorMessage:'',
      recheckName:"",
      RadioSelectedReject: "Not interested in this Session",
      rejectReason:"",
      tutorVerificationTutorName:"",

      gradePopupTitle:'Create New Grade',
      GradeDynemicButton:'Save',
      editGrade:false,
      curriculamselected:null,
      sameGradeErrorMessage:"",
      isInGradeArray: false,
      editGradeId:'',
      oldgradeName:"",
      contactUsTitle:"Create Contact Us",
      contactUsDynemicButton:"Save",
      iscontactUsEdit: false,
      contactUSFormData: {
        title: "",
        description: "",
      },
      faqFormData: {
        title: "",
        description: "",
      },
      faqErrorMessage: "",
      faqPopupTitle: "Create FAQ",
      faqDynemicButton: "Save",
      isInFaqArray: false,
      currentUserData:null,
      isInFaqArrays:false,
      isInContactUsArrays: false,
      isLearner:false,
      isButton:false,
      userRole:[],
      bookingId:'',
      bookingIdForUpcoming: '',
      transectionBookingDetailId: '',
      bookingIdForCurrent: '',
      bookingIdForPast:'',
      sessionStatus: null,
      userName : "",
      editUserName:"",
      editUserRole:[],
      credit:'',
      errorMessageForCredit:'',
      userId: '',
      sessionBookingId: '',
      sessionRequestBookingId: '',
      usersessionId: '',
      bookingSessionId: '',
      totalNoOfWarningOfTutor: 0,
      totalWarnForSuspend: 0,
      transectionHistoryTabDisplay: '',
      tutorStatus:'',
      categoryName:'',
      bannerRequiredImafge:'',
      categoryRequiredImafge:'',
      isFaqEdit: false,
      faqEditID:'',
      contactUsEditID:'',
      isInFaqIdPresent: false,
      faqErrorMessageForEdit: '',
      isInFaqArraysForData: false,
      isInContactUsArraysForData: false,
      isSyllabusEdits: false,
      isSyllabusEdits132:false,
      learnerHistoryBreadcumbDisplay: "",
      allSessionDetailTitle:"",
      contactUSErrorMessage:"",
      contactUSErrorMessageForEdit:"",
      editCategoryName: "",
      categoryIcon:false,
      categoryRequiredImage:'',
      editCate:false,
      isUserChild: false,
      userStatus: null,
      editUserStatus: null,
    };
  },
  created() {
     var self = this;
     self.$root.$on('allSessionDetailTitleName', (sessionTitle) => {
        self.allSessionDetailTitle = sessionTitle;
     })
     self.$root.$on('tutorVerificationName', (tutorName) => {
        self.tutorVerificationTutorName = tutorName;
     });
     //Emit call for booking ID
     self.$root.$on('cancelSessionDetailsForName',(role,userid,bookingID)=>{
      self.bookingId = role
      self.userId = userid
      self.sessionBookingId = bookingID
    })
     self.$root.$on('cancelSessionRequestDetailsForName',(role,userid,bookingID)=>{
      self.bookingSessionId = role
      self.usersessionId = userid
      self.sessionRequestBookingId = bookingID
      // self.tutorStatus = tutorStatus
    })
     self.$root.$on('pastSessionDetailsForName',(role)=>{
      self.bookingIdForPast = role
    })
     self.$root.$on('sessionStatus',(role)=>{
      self.sessionStatus = role
    })
      self.$root.$on('DisplayAddCreditButton',(role)=>{
        self.isUserChild = role
      })
     self.$root.$on('cancelSessionRequestTutorStatus',(role,warning,totalWar)=>{
      self.tutorStatus = role
      self.totalNoOfWarningOfTutor = warning
      self.totalWarnForSuspend = totalWar
    })
     self.$root.$on('currentSessionDetailsForName',(role)=>{
      self.bookingIdForCurrent = role
    })
     self.$root.$on('upcomingSessionDetailsForName',(role)=>{
      self.bookingIdForUpcoming = role
    })
     //Emit call for User role (Karan)
    self.$root.$on('userDetailForUserRole',(role)=>{
      self.userRole = role
      if (role.includes('tutor') && role.includes('learner')){
        this.tutorDetailSwitch = false;
      }
      else if(role.includes('tutor')){
        this.tutorDetailSwitch = true;
      }
      else{
        this.tutorDetailSwitch = false;
      }
    })
    // emit call from transectionBookingDetailPage
    self.$root.$on('transectionHistoryTab',(role)=>{
      self.transectionHistoryTabDisplay = role
    })
    // emit call from userDetail
    self.$root.$on('userDetailForName',(role)=>{
      self.userName = role
    })
    self.$root.$on('categoryName',(role,iconName)=>{
      self.categoryName = role
      self.categoryImageFile = iconName ? iconName : ''
    })
    self.$root.$on('userDetailStatus',(status)=>{
      self.userStatus = status
    })
    // emit call from edituser
    self.$root.$on('editUserDetailForName',(role)=>{
      self.editUserName = role.name
      self.editUserRole = role.role
      self.editUserStatus = role.status
    })
    //emit call from transectionBookingDetail 
    self.$root.$on("transectionBookingDetailId", (role) => {
        self.transectionBookingDetailId = role
    });
    // EMIT CALL FROM BANAERMANAGEMENT.VUE WHEN EDIT ICON CLICK (Shraddha)
    self.$root.$on("stopSpinner", () => {
      
      self.isSpinner = false;
    });
    self.$root.$on('startButtonDisabled', () => {
      
      self.isButton = true;
    });
    self.$root.$on('stopButtonDisabled', () => {
      
      self.isButton = false;
    });
    self.$root.$on("editBannerPopup",(val,id) => {
      
      self.tempArray = [];
      self.ImageFile = '';
      self.isEditPopup = val;
      self.isEditClass = val;
      self.bannerModel = true;
      self.bannerDynemicTitle = 'Edit Banner';
      self.bannerDynemicButton = 'Update';
      self.bannerUserTypes = self.bannersList[id].userType
      self.ImageFile = self.bannersList[id].fileName
      self.tempArray = self.bannersList[id].userType
      self.editId = id
    })
    // EMIT CALL FROM BANAERMANAGEMENT.VUE WHEN VIEW ICON CLICK (Shraddha)
    self.$root.$on("viewBannerPopup",(val,id) => {
      
      self.isSpinner = true;
      self.bannerViewModel = true;
      self.viewId = id;
      self.isSpinner = false;
    })
    // EMIT CALL FROM CATEGORY.VUE WHEN EDIT CATEGORY CLICK(Shraddha)
    self.$root.$off('editCategory')
    self.$root.$on('editCategory',(val,id) => {
      
      self.categoryPopupTitle  = 'Edit Category';
      self.editCategory = val;
      self.editCate = true;
      self.CategoryDynemicButton = 'Update'
      self.editCategoryId = id
      self.categories.forEach(cat => {
        if(cat.id == id){
          self.editCategoryName = cat.categoryName;
          self.categoryImageFile = cat.iconName ? cat.iconName : '';
        }
      })
      self.$bvModal.show('modal-create-category');

    })
    self.$root.$on('editFaqData',(que,ans,id) => {
      self.$bvModal.show('modal-faq');
      self.faqPopupTitle  = 'Edit FAQ';
      self.faqFormData.title = que;
      self.faqDynemicButton = 'Update'
      self.faqFormData.description = ans
      self.faqEditID = id
      self.isFaqEdit = true;
    })
    self.$root.$on('editContactUsData',(que,ans,id) => {
      self.$bvModal.show('modal-contact_us');
      self.contactUsTitle  = 'Edit Contact Us';
      self.contactUSFormData.title = que;
      self.contactUsDynemicButton = 'Update'
      self.contactUSFormData.description = ans
      self.contactUsEditID = id
      self.iscontactUsEdit = true;
    })
    self.$root.$on('learnerHistoryBreadcumb',(name) => {
      self.learnerHistoryBreadcumbDisplay = name
    })
     self.$root.$on('editGrade',(val,id,gradeName) => {
      
      self.$bvModal.show('modal-create-Grade');
      self.gradePopupTitle  = 'Edit Grade';
      self.GradeDynemicButton = 'Update';
      self.editGrade = true;
      self.editGradeId = id;
      self.oldgradeName = gradeName.gradename
      self.name = gradeName.gradename;
      self.curriculamselected = gradeName.curriculamId;
    }) 
    self.$root.$on('editSchool',(val,id)=>{
        self.$bvModal.show('modal-new-school');
        self.schoolId = id
        self.schoolTitle = "Edit School";
        self.schoolDynemicButton = "Update";
        self.isSchoolEdit = true
        self.name = val
        self.schools.forEach(sch => {
        if(sch.id == id){
          self.name = sch.schoolName;
        }
      })

    })
    self.$root.$on('editSyllabus',(val,id)=>{
        self.$bvModal.show('modal-new-curriculum');
        self.syllabusId = id
        self.isSyllabusEdits = true
        self.syllabusTitle = "Edit Curriculum";
        self.syllabusDynemicButton = "Update";
        self.isSyllabusEdit = true
        self.sameCurriculumErrorMessage = '';
        self.name = val
        self.syllabus.forEach(syl => {
        if(syl.id == id){
          self.name = syl.syllabusName;
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      categories:'categoryManagement/getCategories',
      schools:'settings/getSchools',
      users:'userManagement/getUsers',
      bannersList:'bannerManagement/getBanners',
      faqs: "faqManagement/getFaq",
      contactUs: "faqManagement/getContactUs",
      getTutorStatusList:'status/getStatus',
      syllabus:'settings/getSyllabus',
      getPendingBookingList : 'booking/getPendingBooking',
      getsettingList: "settings/getsettingArrayFeild"
    }),    
    Categoryoptions() {
      var arr = [];
      arr.push({ value: null, text: "Select" });
      for (var i = 0; i < this.categories.length; i++) {
        var obj = {
          value: this.categories[i].id,
          text: this.categories[i].categoryName,
        };
        arr.push(obj);
      }
      return arr;
    },
    curriculamoption() {
      var arr = [];
      arr.push({ value: null, text: "Select" });
      for (var i = 0; i < this.syllabus.length; i++) {
        var obj = {
          value: this.syllabus[i].id,
          text: this.syllabus[i].syllabusName,
        };
        arr.push(obj);
      }
      return arr;
    },
    //GET CURRENT ROUTE DATA
    getRouteData(){
      let result = [];
      try{
        if(this.$route.name == "tutor-verification-details"){
          if(this.users && this.users.length > 0){
            let getIndex = this.users.findIndex((element)=>{
              return element.id == this.$route.params.id
            })
            if(getIndex > -1){
              this.currentUserData = this.users[getIndex];
            }
          }
          return this.currentUserData
        }
      }catch(error){
        console.error(error,"HERE");
        return result
      }
    },        
    getTutorStatus(){
      let result = '';
      try{
        if(this.getRouteData && this.getRouteData !== null){
          if(this.getRouteData.tutorStatus && this.getRouteData.tutorStatus !== ''){
            let key = this.getRouteData.tutorStatus ? this.getRouteData.tutorStatus : '';
            result = this.getStatusName(key)
          }
        }
        return result
      }catch(error){
        console.error(error,"ERROR")
        return result
      }
    },
    checkIsLearner(){
      this.isLearner = Vue.prototype.$DetailPage_User_IsLearner;
      return this.isLearner
    }
  },
  methods: {
    ...mapActions({
      addCategory: "categoryManagement/createCategory",
      addSubCategory: "categoryManagement/createSubCategory",
      addSchool : 'settings/createSchool',
      addSyllabus :'settings/createSyllabus',
      addFaq: "faqManagement/createFaq",
    }),
    ...mapMutations({
      'cancelSessionArrayEmpty' : "cancelSessionDetails/setCancelSessionRequestArray",
      'setRejectSessionData' : 'booking/setRejectedBookigData',
      'setupComingSessionArray' : 'upComingSession/setupComingSessionArray',
      'addedNewContactUs' :  'faqManagement/addedNewContactUsTest'

     }),
     //confirm pending session
     ConfirmBooking(){
      try {
        var self = this;
        self.$bvModal
        .msgBoxConfirm('Are you sure you want to confirm this booking request?', {
          title: 'Confirm Booking Request',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header',
          bodyClass: 'approve-body approverequest',
        })
        .then(value => {
          if(value){
            self.isSpinner = true;
            const updateObject = {
              status :1,
              isApprovedByTutor:true,
              isApprovedByLearner:true
            }
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,this.$route.params.id,updateObject,() => {
              self.$root.$emit('showToastMessage','Booking confirmed successfully.','success');
              self.isSpinner = false;
              var index = self.getPendingBookingList.findIndex(item => {
                return item.id == this.$route.params.id
              })

              adminBookingNotification({
                bookingData : {...self.getPendingBookingList[index], status:1}
              }, () => {});
              if(index != -1){
                  self.getPendingBookingList.splice(index, 1);
                  self.setupComingSessionArray([]);
              }
              self.$router.push({name:'upcoming-session-mgmt'});
            });
          }
        })
      } catch (error) {
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "ConfirmBooking",
          errorMessage : err.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
      }
    },

    rejectbookingresetModal() {
      this.name = "";
      this.nameState = null;
    },
    rejectbookinghandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.rejectbookinghandleSubmit(bvModalEvt);   
    },
    rejectbookinghandleSubmit() {
      try {
        this.$refs.formCancel.validate().then(async(valid)=>{
          if(valid){
            this.$bvModal.hide("modal-reject-detail-booking");
            this.isSpinner = true;
            try {
              await db.collection(dbCollections.BOOKING).doc(this.$route.params.id).get().then(async (resBooking) => {
                  if(resBooking.exists) {
                    let selctedData = resBooking.data();
                    await db.collection(dbCollections.CREDITTRANSACTIONS)
                            .where("userId","==",selctedData.learnerId)
                            .orderBy("createdAt", "desc")
                            .limit(1)
                            .get()
                            .then(async(docData) => {
                              if(docData.empty){
                              }else{
                                  let sengleBookingData = docData.docs[0].data();                                         
                                  const updateObj = {
                                    currentCredit : Number(sengleBookingData.availableCredit) + Number(selctedData.usedCredits)
                                  }
                                  await updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,selctedData.learnerId,updateObj,async(res) => {
                                    if(!res){
                                      return;
                                    }
                                    const learnerDeatil = {
                                      firstName : selctedData.learnerDetails.firstName,
                                      lastName :selctedData.learnerDetails.lastName,
                                      profileImage: selctedData.learnerDetails.profileImage
                                    }
                                    const transactionObject = {
                                      availableCredit:Number(sengleBookingData.availableCredit) + Number(selctedData.usedCredits),
                                      bookingId:selctedData.bookingId,
                                      createdAt:new Date(),
                                      credits:Number(selctedData.usedCredits),
                                      id:'',
                                      paymentId:'',// This feild is static
                                      reference:1,
                                      remarks:'Booking rejected.',
                                      transactionType:"cr",
                                      userDetail:learnerDeatil,
                                      userId: selctedData.learnerId,
                                      isSendNotification: false
                                    }
                                    insertFirebase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,async(credit) => {
                                      if(credit){
                                        const updateObjCredit = {
                                          id : credit.id
                                        }
                                        await updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,credit.id,updateObjCredit,async(creditUpdate) => {
                                            const cancelreasondetail = {
                                            cancellationReason: this.RadioSelectedReject === "Other"? this.rejectReason : this.RadioSelectedReject,
                                            status: 2,
                                            cancellationDate: new Date(),
                                            updatedAt: new Date(),
                                            usedCredits: 0
                                          };
                                          updateFirebase.updateRootCollectionDataByDocId(
                                            dbCollections.BOOKING,
                                            selctedData.id,
                                            cancelreasondetail,
                                            async(res) => {
                                              if(!res){
                                                return;
                                              }
                                              let index = this.getPendingBookingList.findIndex((data) =>{
                                                  return data.id == selctedData.id
                                              });
                                              adminBookingNotification({
                                                bookingData : {...this.getPendingBookingList[index], status:2, cancellationReason: this.RadioSelectedReject === "Other"? this.rejectReason : this.RadioSelectedReject}
                                              }, () => {});
                                              if(index > -1){
                                                  this.getPendingBookingList.splice(index , 1);
                                                  this.setRejectSessionData([]);
                                              }
                                              this.$router.push({
                                                name:'rejected-session-mgmt'
                                              })
                                              this.$root.$emit(
                                                "showToastMessage",
                                                "Rejected session successfully.",
                                                "success"
                                              );
                                              this.isSpinner = false;
                                            });
                                        })
                                      }
                                    })
                                  })  
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            })

                  }
              }).catch((error) => {
                  console.error(error);
              })
            } catch (error) {
              console.error("error", error);
              this.isUpdating = false
            }
          }
        })
      } catch (error) {
        console.error("error",error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "cancelbookinghandleSubmit",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    openConfirmationModel(type){
      if(type == 'Reject'){       
        this.rejectingTutor()
          }    
          
    },    
    rejectingTutor(){
      this.$root.$emit('rejectTutorVerificationEmit');
    },
    getStatusName(statusKey){
      var status = "";
      try{
        if(this.getTutorStatusList && this.getTutorStatusList !== null){

          let statusObj =  this.getTutorStatusList.tutorVerificationStatus;
          for (const property in statusObj) {            
            if(statusKey == property){  
                status = statusObj[property].status;
            }      
          }
        }
      }catch(error){
        console.error(error,"error");
        return status;
      }
      return status;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.submittedNames.push(this.name);
    },
    CategoryresetModal() {
      this.editCategoryName = "";
      this.nameState = null;
      this.isCategoryImage = true;
      this.sameCategoryErrorMessage = '';
      this.categoryImageFile = '';
      this.categoryIcon = false;
      this.editCate = false;
    },
    CategoryhandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    CategoryhandleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.createCategory(bvModalEvt);
    },
    //OPEN CATEGORY CREATE POPUP(Shraddha)
    openCategoryCreate(){
      
      var self = this;
      self.categoryPopupTitle  = 'Create Category';
      self.editCategory = false;
      self.CategoryDynemicButton = 'Save';
      self.$bvModal.show('modal-create-category');
    },
    // CHECK IF CATEGORY NAME IS ALREDY EXIST OR NOT (Shraddha)
    CheckCategoryNameExist(){
      var self = this;
        self.isInArray = self.categories.some(function(item) {
            return item.categoryName.toLowerCase().trim() === self.editCategoryName.toLowerCase().trim() && item.id !== self.editCategoryId
        });
        if(self.isInArray && self.editCategoryName != ''){
            self.sameCategoryErrorMessage = 'The category name is already exist'
        }else{
            self.sameCategoryErrorMessage = '';
        }
    },
    CheckSchoolNameExit(){
      var self = this;
      self.isInArray = self.schools.some(function(item) {
          return item.schoolName.toLowerCase().trim()  == self.name.toLowerCase().trim() && item.id !== self.schoolId
      });
      if(self.isInArray && self.name != ''){
        self.sameSchoolErrorMessage = 'The school name is already exist'
      }else{
        self.sameSchoolErrorMessage= '';
      }
    },
    CheckCurriculumNameExit(){
      var self = this;
      self.isInArray = self.syllabus.some(function(item) {
          return item.syllabusName.toLowerCase().trim() === self.name.toLowerCase().trim() && item.id !== self.syllabusId
        });
        if(self.isInArray && self.name != ''){
            self.sameCurriculumErrorMessage = 'The curriculum name is already exist'
        }else{
            self.sameCurriculumErrorMessage= '';
        }
    },
    checkCategoryFile(e)
    {
      
      var self = this;
      self.categoryRequiredImage = '';
      self.categoryImageFile = '';
      self.isEditClass = false;
      self.categoryIcon = true;
      self.editCate = false;
      var files = e.target.files || e.dataTransfer.files;
      const maxBytes = 200000;
      self.isCategoryImage=true
      if(files.length!=0)
      {
        self.categoryImageFile=''
      }
      try{
        files.forEach(data=>{
            if(data.type.toLowerCase() !== 'image/png' 
            && data.type.toLowerCase() !== "image/jpeg" 
            && data.type.toLowerCase() !== "image/jpg" 
            && data.type.toLowerCase() !== 'png'
            && data.type.toLowerCase() !== 'jpeg'
            && data.type.toLowerCase() !== 'jpg')
            {
              self.isCategoryImage=false
            }
        })
        if(files[0].size > maxBytes)
        {
          self.isCategoryImage=false;
        }
        var found = false;
        if(self.isCategoryImage){
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if(height <= 512 && width <= 512){
                found = true;
              }else{
                found = false;
              }
              if(found){
                self.isCategoryImage=true;
                self.categoryImageFile = files[0];
              }else{
                self.isCategoryImage=false;
              }
            };
          }
        }
      }
      catch(error){
        document.getElementById("categoryImagePicker").value="";
        self.categoryImageFile='';
      }
    },
    //CREATE CATEGORY WHEN OPEN POPUP(Shraddha)
    createCategory() {
      
      var self = this;
      self.$refs.Categoryform.validate()
      .then((valid)=>{
        if(valid && !self.isInArray && self.isCategoryImage && self.sameCategoryErrorMessage == ''){
          self.isSpinner = true;
          const categoryName = this.editCategoryName;
          if(self.categoryIcon){
            self.categoryRequiredImage = '';
              storageFirebase.uploadImageFiles("categoryIcon",self.categoryImageFile.name,self.categoryImageFile,(response) => {
                self.$bvModal.hide("modal-create-category");
                if(!self.editCategory){
                  try {
                    var categoryObj = {
                      categoryName: categoryName,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                      icon: response.imageUrl,
                      iconName:response.imageName
                    };
                      insertFirebase.addDataToRootSubCollectionWithDocKey(
                      dbCollections.SETTINGS,
                      "setting",
                      dbCollections.CATEGORY,
                      categoryObj,
                      (res) => {
                        self.$root.$emit('showToastMessage','Category saved successfully.','success');
                        self.$bvModal.hide("modal-create-category");
                          self.$nextTick(() => {
                            self.isSpinner = false;
                          });
                        const catid = {
                          id: res.id,
                        };
                        updateFirebase.updateRootSubCollectionDataWithDocId(
                          dbCollections.SETTINGS,
                          "setting",
                          dbCollections.CATEGORY,
                          res.id,
                          catid,
                          (res) => {
                            self.isSpinner = false;
                            self.$root.$emit("stopSpinner");
                          }
                        );
                      }
                    );
                  }catch (error) {
                    console.error("error", error);
                    self.$nextTick(() => {
                      self.$bvModal.hide("modal-create-category");
                      self.isSpinner = false;
                    });
                  }
                }else{
                  var categoryObject = {
                    categoryName : self.editCategoryName ? self.editCategoryName : categoryName,
                    icon: response.imageUrl,
                    iconName:response.imageName,
                    updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                  }
                  updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.CATEGORY,
                  self.editCategoryId,categoryObject,(res) => {
                    self.$root.$emit("afteEditName",self.editCategoryName, self.categoryImageFile.name)
                    self.editCategoryId = "";
                    self.$root.$emit('showToastMessage','Category updated successfully.','success');
                    self.$nextTick(() => {
                      self.isSpinner = false;
                    });
                  }).catch(error => {
                self.isSpinner = false;
              })
                }
              })
          }else if(!self.categoryIcon && self.editCategory){
            self.$bvModal.hide("modal-create-category");
            self.isSpinner = false;
            var categoryObject = {
              categoryName : self.editCategoryName ? self.editCategoryName : categoryName,
              updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
            }
            updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.CATEGORY,
            self.editCategoryId,categoryObject,(res) => {
              self.$root.$emit("afteEditName",self.editCategoryName)
              self.editCategoryId = "";
              self.$root.$emit('showToastMessage','Category updated successfully.','success');
              self.$nextTick(() => {
                self.isSpinner = false;
              });
            })
          }else if(!self.categoryIcon && !self.editCategory){
            self.categoryRequiredImage = "Category icon is required.";
            self.isSpinner = false;
          }
        }else{
          self.isSpinner = false;
        }
      })
    },
    //OPEN GRADE CREATE POPUP(Karan)
    openGradeCreate(){
      
      var self = this;
      self.gradePopupTitle  = 'Create New Grade';
      self.editGrade = false;
      self.GradeDynemicButton = 'Save';
      self.$bvModal.show('modal-create-Grade');
    },
    GraderesetModal() {
      this.name = "";
      this.nameState = null;
      this.curriculamselected = null;
      this.sameGradeErrorMessage = '';

    },
    GradehandleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.createGrade();
    },
    createGrade() {
      
      var self = this;
       self.isSpinner = true;
      var selectedGradeArray = [];
      self.sameGradeErrorMessage = '';
      db.collection(dbCollections.SETTINGS).doc('setting').collection(dbCollections.GRADE).where('curriculumId','==',self.curriculamselected).get().then(docData => {
        if(!docData.empty){
          docData.forEach(doc => {
            selectedGradeArray.push(doc.data());
          })
        }
      }).then(() => {
        if(selectedGradeArray.length > 0){
          self.isInGradeArray = selectedGradeArray.some(function(item) {
            return item.gradeName.toLowerCase() == self.name.toLowerCase()
          });
        }else{
          self.isInGradeArray = false
        }
         self.$refs.Gradeform.validate()
        .then(async (valid)=>{
          if(valid && self.sameGradeErrorMessage == ''){
            if(!self.isInGradeArray){
              if(!self.editGrade){
                self.$bvModal.hide("modal-create-Grade");
                self.isSpinner = true;
                try {
                  let newDocId = await db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.GRADE).doc().id
                  var gradeObject = {
                    id: newDocId,
                    curriculumId: this.curriculamselected,
                    gradeName: this.name,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                  };            
                  db.collection(dbCollections.SETTINGS)
                    .doc("setting")
                    .collection(dbCollections.GRADE)
                    .doc(newDocId)
                    .set(gradeObject)
                    .then(() => {
                        self.$root.$emit('showToastMessage','Grade saved successfully.','success');
                        self.isSpinner = false;
                        self.$nextTick(() => {
                          self.isSpinner = false;
                        });
                    }).catch((error) => {
                        console.error(error);
                        self.isSpinner = false;
                    })
                }catch (error) {
                  console.error("error", error);
                  self.isSpinner = false;
                  self.$nextTick(() => {
                    self.$bvModal.hide("modal-create-Grade");
                    self.isSpinner = false;
                  });
                }
              }else{
                self.$bvModal.hide("modal-create-Grade");
                self.isSpinner = true;
                var gradeObject = {
                  curriculumId: this.curriculamselected,
                  gradeName : self.name,
                  updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                }
                updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.GRADE,self.editGradeId,gradeObject,(res) => {
                  self.$root.$emit('showToastMessage','Grade updated successfully.','success');
                  self.$nextTick(() => {
                    self.isSpinner = false;
                  });
                })
              }
            }else{
                self.$bvModal.hide("modal-create-Grade");
              if(self.oldgradeName == self.name && self.editGrade){
                self.sameGradeErrorMessage = ''
                self.isSpinner = true;
                var gradeObject = {
                  curriculumId: this.curriculamselected,
                  gradeName : self.name,
                  updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                }
                updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.GRADE,self.editGradeId,gradeObject,(res) => {
                  self.$root.$emit('showToastMessage','Grade updated successfully.','success');
                  self.$nextTick(() => {
                    self.isSpinner = false;
                  });
                })

              }else{
                if(self.name != '' ){  
                  self.isSpinner = false;
                  self.sameGradeErrorMessage = 'The grade name is already exist'
                }
                else{
                   self.sameGradeErrorMessage = ''
                }
              }
            }
          }else{
            self.isSpinner = false;
          }
        })
      }).catch(err => {
        console.error(err)
         self.isSpinner = false;
      })
    },
    //RESET CREDIT MODEL(Shraddha)
    CreditresetModal(){
      var self = this;
      self.credit = '';
      self.errorMessageForCredit == '';
    },
    closeFireCredit(){
      
      var self = this;
      self.credit = '';
      self.errorMessageForCredit == '';
    },
    CreditHandleSubmit(bvModalEvt){
      bvModalEvt.preventDefault();
      this.AddCredit(bvModalEvt);
    },
    //ADD CREDIT TO USER AND ADD TRANSACTION ENTRY IN DATABASE(Shraddha)
    AddCredit(){
      
      var self = this;
      self.$refs.creditForm.validate()
      .then((valid)=>{
        if(valid){
          try{
            if(self.credit !='' && self.credit != 0){
              self.isSpinner = true;
              var index = self.users.findIndex(items => {
                  return items.id == self.$route.params.id
              })
              const updateObj = {
                currentCredit : self.users[index].currentCredit + Number(self.credit)
              }
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,self.users[index].id,updateObj,(res) => {
              })
              const learnerDeatil = {
                firstName :self.users[index].firstName,
                lastName : self.users[index].lastName,
                profileImage:self.users[index].profilePicture
              }
              const transactionObject = {
                availableCredit:self.users[index].currentCredit + Number(self.credit),
                bookingId:'',
                createdAt:new Date(),
                credits:Number(self.credit),
                id:'',
                paymentId:'',// This feild is static
                reference:1,
                remarks:'Received credits from tutorline',
                transactionType:"cr",
                userDetail:learnerDeatil,
                userId:self.users[index].id,
                isSendNotification: false
              }
              insertFirebase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,(res) => {
                if(res.id){
                  const updateObj = {
                    id : res.id
                  }
                  updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,res.id,updateObj,(res) => {
                    self.$root.$emit('showToastMessage','Credit added successfully.','success');
                    self.$root.$emit('displayCredit',transactionObject.credits);
                    self.$bvModal.hide("modal-add-credit");
                    self.isSpinner = false;
                    self.credit = '';
                  })
                }
              })
            }else{
              self.errorMessageForCredit = 'The no. of credit field is required';
            }
          }catch (error) {
            console.error(error)
            self.isSpinner = false;
          }
        }
      })
    },
    openSchoolCreate(){
        var self = this;
        self.schoolTitle  = 'Create New School';
        self.isSchoolEdit = false;
        self.schoolDynemicButton = 'Save'
        
    },
    schoolresetModal() {
      this.name = "";
      this.nameState = null;
      this.sameSchoolErrorMessage = '';
    },
    schoolhandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    schoolhandleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.createSchool(bvModalEvt)
    },
    createSchool(){
      var self = this;
      self.$refs.Schoolform.validate().then((valid)=>{
        if(valid && !self.isInArray && self.sameSchoolErrorMessage == ''){
             self.$bvModal.hide("modal-new-school");
          if(!self.isSchoolEdit){
            self.isSpinner = true;
            try {
               var schoolObj ={
                      id:'',
                      createdAt: new Date(),
                      updatedAt: new Date(),
                      schoolName : this.name
                  }
              self.addSchool(schoolObj)
                .then((res) => {
                    self.$root.$emit('showToastMessage','School name saved successfully.','success');
                    self.isSpinner = false;
                    self.$nextTick(() => {
                      self.isSpinner = false;
                    });
                  })
                .catch((error) => {
                  console.error(error);
                  self.isSpinner = false;
                });
            }catch (error) {
              console.error("error", error);
              self.isSpinner = false;
              self.$nextTick(() => {
                self.$bvModal.hide("modal-new-school");
                self.isSpinner = false;
              });
            }
          }else{
            self.isSpinner = true;
            var updateObj = {
             schoolName : self.name
            }
            updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS, "setting" ,dbCollections.SCHOOL,self.schoolId,updateObj,(res) => {
              self.schoolId = ""
              self.$root.$emit('showToastMessage','School name updated successfully.','success');
              self.$nextTick(() => {
                self.$bvModal.hide("modal-new-school");
                self.isSpinner = false;
              });
            })
          }
        }
      })
    },
    openSyllabusCreate(){
        var self = this;
        self.syllabusTitle  = 'Create New Curriculum';
        self.isSyllabusEdit = false;
        self.syllabusDynemicButton = 'Save'
        self.$bvModal.show('modal-new-curriculum');
    },
    syllabusresetModal() {
      this.name = "";
      this.sameCurriculumErrorMessage = ''
      this.nameState = null;
    },
    syllabushandleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.craeteSyllabus(bvModalEvt)
    },
    craeteSyllabus(){
      var self = this;
      if(self.isSpinner === true){
        return;
      }
      self.$refs.Curriculumform.validate()
      .then(async(valid)=>{
        if(valid  && self.sameCurriculumErrorMessage == ''){
        self.$bvModal.hide("modal-new-curriculum");
          if(!self.isSyllabusEdit && !self.isInArray){
            self.isSpinner = true;
            try {
              let newDocId = await db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.SYLLABUS).doc().id
              var syllabusObj ={
                id:newDocId,
                createdAt: new Date(),
                updatedAt: new Date(),
                syllabusName : this.name
              }
              db
                .collection(dbCollections.SETTINGS)
                .doc("setting")
                .collection(dbCollections.SYLLABUS)
                .doc(newDocId)
                .set(syllabusObj)
                .then(()=>{
                  self.$root.$emit('showToastMessage','Curriculum name saved successfully.','success');
                  self.isSpinner = false;
                  self.$nextTick(() => {
                    self.isSpinner = false;
                  });
                })
                .catch(error=>{
                  console.error("error",error.message);
                })
            }catch (error) {
              console.error("error", error);
              self.isSpinner = false;
              self.$nextTick(() => {
                self.isSpinner = false;
                self.$bvModal.hide("modal-new-curriculum");
              });
            }
          }else{
            self.isSpinner = true;
            var updateObj = {
             syllabusName : this.name
            }
            updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS, "setting" ,dbCollections.SYLLABUS,self.syllabusId,updateObj,(res) => {
              self.syllabusId = "";
              self.$root.$emit('showToastMessage','Curriculum name updated successfully.','success');
              self.$nextTick(() => {
                self.isSpinner = false;
                self.$bvModal.hide("modal-new-curriculum");
              });
            })
          }
        }
      })
     
    },
    openFaqForm() {
      var self = this;
      self.$bvModal.show('modal-faq')
      self.faqPopupTitle = "Create FAQ";
      self.faqDynemicButton = "Save";
      self.isFaqEdit = false;
    },
    faqresetModal() {
      var self = this;
      self.faqFormData.title = "";
      self.faqFormData.description = "";
      self.nameState = null;
      self.faqErrorMessage = "";
      self.faqErrorMessageForEdit = '';
    },
    faqhandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.faqhandleSubmit();
    },
     openContactUsForm() {
      var self = this;
      self.$bvModal.show('modal-contact_us')
      self.contactUsTitle = "Create Contact Us";
      self.contactUsDynemicButton = "Save";
      self.iscontactUsEdit = false;
    },
     contactUSresetModal() {
      var self = this;
      self.contactUSFormData.title = "";
      self.contactUSFormData.description = "";
      self.nameState = null;
      self.contactUSErrorMessage = "";
      self.contactUSErrorMessageForEdit = '';
    },
    contactUshandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.contactUshandleSubmit();
    },
    CheckValidQuestion(){
      let self = this;
      if(self.isFaqEdit === false){
        if(this.faqFormData.title === this.faqFormData.description && this.faqFormData.description != '' && this.faqFormData.title != ''){
          this.faqErrorMessage = "The title and description are the same"
        }else{
          this.faqErrorMessage = ""
        }
      }else if(self.isFaqEdit === true){
        if(this.faqFormData.title === this.faqFormData.description){
          this.faqErrorMessageForEdit = "The title and description are the same"
        }else{
          this.faqErrorMessageForEdit = ""
        }
      }
    },
    CheckValidQuestionContactUs(){
      let self = this;
      if(self.iscontactUsEdit === false){
        if(this.contactUSFormData.title === this.contactUSFormData.description && this.contactUSFormData.description != '' && this.contactUSFormData.title != ''){
          this.contactUSErrorMessage = "The title and description are the same"
        }else{
          this.contactUSErrorMessage = ""
        }
      }else if(self.iscontactUsEdit === true){
        if(this.contactUSFormData.title === this.contactUSFormData.description){
          this.contactUSErrorMessageForEdit = "The title and description are the same"
        }else{
          this.contactUSErrorMessageForEdit = ""
        }
      }
    },
    CheckInArray(){
      let self = this;
      if(self.isFaqEdit === false){
        if (self.faqs.length > 0) {
          self.isInFaqArrays = self.faqs.some(function (item) {
            return item.question.toLowerCase() === self.faqFormData.title.toLowerCase()
          });
        }
        if(self.isInFaqArrays && self.faqFormData.title != '' && self.isFaqEdit === false){
          self.faqErrorMessage = 'The title is already exist'
        }else{
          self.faqErrorMessage = '';
        }
      }else if(self.isFaqEdit === true){
        if (self.faqs.length > 0) {
          self.isInFaqArraysForData = self.faqs.some(function (item) {
            return (item.question.toLowerCase() === self.faqFormData.title.toLowerCase() && item.id !== self.faqEditID)
          });
        }
        if(self.isInFaqArraysForData && self.faqFormData.title != ''){
          self.faqErrorMessageForEdit = 'The title is already exist'
        }else{
          self.faqErrorMessageForEdit = '';
        }
      }
    },
    CheckInArrayContactUs(){
      let self = this;
      if(self.iscontactUsEdit === false){
        if (self.contactUs.length > 0) {
          self.isInContactUsArrays = self.contactUs.some(function (item) {
            return item.question.toLowerCase() === self.contactUSFormData.title.toLowerCase()
          });
        }
        if(self.isInContactUsArrays && self.contactUSFormData.title != '' && self.iscontactUsEdit === false){
          self.contactUSErrorMessage = 'The title is already exist'
        }else{
          self.contactUSErrorMessage = '';
        }
      }else if(self.iscontactUsEdit === true){
        if (self.contactUs.length > 0) {
          self.isInContactUsArraysForData = self.contactUs.some(function (item) {
            return (item.question.toLowerCase() === self.contactUSFormData.title.toLowerCase() && item.id !== self.contactUsEditID)
          });
        }
        if(self.isInContactUsArraysForData && self.contactUSFormData.title != ''){
          self.contactUSErrorMessageForEdit = 'The title is already exist'
        }else{
          self.contactUSErrorMessageForEdit = '';
        }
      }
    },
    faqhandleSubmit() {
      try {
        var self = this;
        if(!this.isFaqEdit){
          this.CheckValidQuestion()
          if(this.faqErrorMessage !== ''){
            return;
          }
          if (this.faqs.length > 0) {
            self.isInFaqArray = this.faqs.some(function (item) {
              return (
                item.question.toLowerCase().trim() == self.faqFormData.title.toLowerCase().trim()
              );
            });
          } else {
            self.isInFaqArray = false;
          }
          if (!self.isInFaqArray) {
            var self = this;
            self.faqErrorMessage = "";
            self.$refs.faqForm.validate().then((valid) => {
              if (valid) {
                try {
                  this.isButton = true;
                  self.$bvModal.hide("modal-faq");
                  self.$root.$emit('startFaqSpinner');
                  var faqObj = {
                    question: self.faqFormData.title,
                    answer: self.faqFormData.description,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false
                  };
                  self.addFaq(faqObj)
                    .then((res) => {
                      self.$root.$emit(
                        "showToastMessage",
                        "Faq saved successfully.",
                        "success"
                      );
                      self.isSpinner = false;
                      self.$nextTick(() => {
                        self.$bvModal.hide("modal-faq");
                        self.$root.$emit('stopFaqSpinner');
                        this.isButton = false;
                      });
                    })
                    .catch((error) => {
                      console.error("catch error", error);
                      self.$root.$emit('stopFaqSpinner');
                      this.isButton = false;
                    });
                } catch (error) {
                  console.error("err", error);
                  self.$root.$emit('stopFaqSpinner');
                  self.$nextTick(() => {
                    self.$bvModal.hide("modal-faq");
                    self.$root.$emit('stopFaqSpinner');
                  });
                }
              }
            });
          } else if(self.isInFaqArray) {
            self.$refs.faqForm.validate().then((valid) => {
              if (valid) {
                this.CheckInArray()
              }
            })
          }
        }else{
          this.CheckValidQuestion()
          if(this.faqErrorMessageForEdit !== ''){
            return;
          }
          if (this.faqs.length > 0) {
            self.isInFaqArray = this.faqs.some(function (item) {
              return (
                item.question.toLowerCase() === self.faqFormData.title.toLowerCase() && item.id !== self.faqEditID
              );
            });
          } else {
            self.isInFaqArray = false;
          }
          if (!self.isInFaqArray) {
            var self = this;
            self.faqErrorMessageForEdit = "";
            self.$refs.faqForm.validate().then((valid) => {
              if (valid) {
                try {
                  this.isButton = true;
                  self.$bvModal.hide("modal-faq");
                  self.$root.$emit('startFaqSpinner');
                  var faqObj = {
                    question: self.faqFormData.title,
                    answer: self.faqFormData.description,
                    createdAt: new Date(),
                    updatedAt: new Date()
                  };
                  updateFirebase.updateRootSubCollectionDataWithDocId(
                    dbCollections.SETTINGS,"setting",
                    dbCollections.FAQ,
                    this.faqEditID,
                    faqObj,
                    (res) => {
                      self.$root.$emit(
                        "showToastMessage",
                        "Faq updated successfully.",
                        "success"
                      );
                      let newObject = {...faqObj,id:this.faqEditID}
                      this.$root.$emit('faqUpdate',newObject)
                      this.$root.$emit('stopFaqSpinner');
                      this.isButton = false;
                      this.faqEditID = '';
                    }
                  );
                } catch (error) {
                  console.error("err", error);
                  setTimeout(()=>{
                    self.$root.$emit('stopFaqSpinner');
                  })
                  this.isButton = false;
                  self.$nextTick(() => {
                    self.$bvModal.hide("modal-faq");
                  });
                }
              }
            });
          } else if(self.isInFaqArray) {
            self.$refs.faqForm.validate().then((valid) => {
              if (valid) {
                this.CheckInArray()
              }
            })
          }
        }
      } catch (error) {
       console.error(error) 
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "faqhandleSubmit",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    contactUshandleSubmit() {
      try {
        var self = this;
        if(!this.iscontactUsEdit){
          this.CheckValidQuestionContactUs()
          if(this.contactUSErrorMessage !== ''){
            return;
          }
          if (this.contactUs.length > 0) {
            self.isInContactUsArrays = this.contactUs.some(function (item) {
              return (
                item.question.toLowerCase().trim() == self.contactUSFormData.title.toLowerCase().trim()
              );
            });
          } else {
            self.isInContactUsArrays = false;
          }
          if (!self.isInContactUsArrays) {
            var self = this;
            self.contactUSErrorMessage = "";
            self.$refs.contactUsForm.validate().then((valid) => {
              if (valid) {
                try {
                  this.isButton = true;
                  this.$bvModal.hide("modal-contact_us");
                  self.$root.$emit('startContactUsSpinner');
                  var contactUsObj = {
                    question: self.contactUSFormData.title,
                    answer: self.contactUSFormData.description,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false
                  };
                   const newdata = db.collection(dbCollections.SETTINGS).doc("setting")
                    newdata.collection(dbCollections.CONTACT_US).add(contactUsObj)
                    .then((res)=>{
                      let updatedObject = {
                          id:res.id
                      }
                      newdata.collection(dbCollections.CONTACT_US).doc(res.id).update(updatedObject).then(()=>{
                        this.addedNewContactUs({...contactUsObj, id: res.id});
                        self.$root.$emit(
                        "showToastMessage",
                        "Contact us saved successfully.",
                        "success"
                        );
                        self.isSpinner = false;
                        self.$nextTick(() => {
                          self.$bvModal.hide("modal-contact_us");
                          self.$root.$emit('stopContactUsSpinner');
                          this.isButton = false;
                        });
                      }).catch((error) => {
                      console.error("catch error", error);
                      self.$root.$emit('stopContactUsSpinner');
                      this.isButton = false;
                    });
                  });            
                } catch (error) {
                  console.error("err", error);
                  self.$root.$emit('stopContactUsSpinner');
                  self.$nextTick(() => {
                    self.$bvModal.hide("modal-contact_us");
                    self.$root.$emit('stopContactUsSpinner');
                  });
                }
              }
            });
          } else if(self.isInContactUsArrays) {
            self.$refs.contactUsForm.validate().then((valid) => {
              if (valid) {
                this.CheckInArrayContactUs()
              }
            })
          }
        }else{
          this.CheckValidQuestionContactUs()
          if(this.contactUSErrorMessageForEdit !== ''){
            return;
          }
          if (this.contactUs.length > 0) {
            self.isInContactUsArrays = this.contactUs.some(function (item) {
              return (
                item.question.toLowerCase() === self.contactUSFormData.title.toLowerCase() && item.id !== self.contactUsEditID
              );
            });
          } else {
            self.isInContactUsArrays = false;
          }
          if (!self.isInContactUsArrays) {
            var self = this;
            self.contactUSErrorMessageForEdit = "";
            self.$refs.contactUsForm.validate().then((valid) => {
              if (valid) {
                try {
                  this.isButton = true;
                  self.$bvModal.hide("modal-contact_us");
                  self.$root.$emit('startFaqSpinner');
                  var contactUsObj = {
                    question: self.contactUSFormData.title,
                    answer: self.contactUSFormData.description,
                    createdAt: new Date(),
                    updatedAt: new Date()
                  };
                  updateFirebase.updateRootSubCollectionDataWithDocId(
                    dbCollections.SETTINGS,"setting",
                    dbCollections.CONTACT_US,
                    this.contactUsEditID,
                    contactUsObj,
                    (res) => {
                      self.$root.$emit(
                        "showToastMessage",
                        "Contact us updated successfully.",
                        "success"
                      );
                      let newObject = {...contactUsObj,id:this.contactUsEditID}
                      this.$root.$emit('contactUsUpdate',newObject)
                      this.$root.$emit('stopContactUsSpinner');
                      this.isButton = false;
                      this.contactUsEditID = '';
                    }
                  );
                } catch (error) {
                  console.error("err", error);
                  setTimeout(()=>{
                    self.$root.$emit('stopContactUsSpinner');
                  })
                  this.isButton = false;
                  self.$nextTick(() => {
                    self.$bvModal.hide("modal-contact_us");
                  });
                }
              }
            });
          } else if(self.isInContactUsArrays) {
            self.$refs.contactUsForm.validate().then((valid) => {
              if (valid) {
                this.CheckInArrayContactUs()
              }
            })
          }
        }
      } catch (error) {
       console.error(error) 
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "contactUshandleSubmit",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    bannerresetModal() {
      
      this.name = "";
      this.nameState = null;
      this.isBannerImage = true;
      if(this.tempArray.length == 0){
        this.bannerUserTypes = [];
      }
    },
    isCharAt(evt)
    {
      const char = String.fromCharCode(evt.keyCode);
        if (!/[0-9 ||.]/.test(char)) {
            evt.preventDefault();
        }
    },
    bannerhandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.bannerhandleSubmit(bvModalEvt);
    },
    checkBannerFile(e)
    {
      
      var self = this;
      self.bannerRequiredImafge = '';
      self.ImageFile = '';
      self.isEditClass = false;
      var files = e.target.files || e.dataTransfer.files;
      const maxBytes = 200000;
      self.isBannerImage=true
      if(files.length!=0)
      {
        self.bannerImageFile={}
      }
      try{
        files.forEach(data=>{
            if(data.type.toLowerCase() !== 'image/png' 
            && data.type.toLowerCase() !== "image/jpeg" 
            && data.type.toLowerCase() !== "image/jpg" 
            && data.type.toLowerCase() !== 'png'
            && data.type.toLowerCase() !== 'jpeg'
            && data.type.toLowerCase() !== 'jpg')
            {
              self.isBannerImage=false
            }
        })
        if(files[0].size > maxBytes)
        {
          self.isBannerImage=false;
        }
        var found = false;
        if(self.isBannerImage){
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if(height <= 153 && width <= 394){
                found = true;
              }else{
                found = false;
              }
              if(found){
                self.isBannerImage=true;
                self.bannerImageFile = files[0];
              }else{
                self.isBannerImage=false;
                
              }
            };
          }
        }
      }
      catch(error){
        document.getElementById("bannerImagePicker").value="";
        self.bannerImageFile={};
      }
    },
    bannerhandleSubmit() {
      
      var self = this;
      self.$refs.addBannerForm.validate()
      .then((valid)=>{
        if(valid && self.isBannerImage  && !self.isEditPopup)
        {
            if(Object.keys(self.bannerImageFile).length > 0){
            self.bannerRequiredImafge = '';
            self.isSpinner = true;
                storageFirebase.uploadImageFiles(dbCollections.BANNERMANAGEMENTSTORAGE,self.bannerImageFile.name,self.bannerImageFile,(res) => {
                if(!self.isEditPopup){
                    var bannerObject = {
                        createdAt : firebase.firestore.FieldValue.serverTimestamp(),
                        fileName:self.bannerImageFile.name,
                        fileSizeInKB : self.bytesToSize(self.bannerImageFile.size),
                        status:0,
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                        uploadFile : res.imageUrl,
                        userType:self.bannerUserTypes,
                    }
                    insertFirebase.addDataToRootSubCollectionWithDocKey(dbCollections.SETTINGS,'setting',dbCollections.BANNERMANAGEMENT,bannerObject,(res) => {
                    self.$root.$emit('showToastMessage','Banner saved successfully.','success');
                    self.$nextTick(() => {
                      self.$bvModal.hide("modal-banner");
                      self.closeFire();
                        self.isSpinner = false;
                    });
                    })
                }else{
                    var bannerObject = {
                        fileName:self.bannerImageFile.name,
                        fileSizeInKB : self.bytesToSize(self.bannerImageFile.size),
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                        uploadFile : res.imageUrl,
                        userType:self.bannerUserTypes,
                    }
                    updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.BANNERMANAGEMENT,self.bannersList[self.editId].id,bannerObject,(res) => {
                        self.$root.$emit('showToastMessage','Banner updated successfully.','success');
                        self.$nextTick(() => {
                          self.$bvModal.hide("modal-banner");
                          self.closeFire();
                            self.isSpinner = false;
                        });
                    })
                }
                })
            }else{
                self.bannerRequiredImafge = 'The upload file field is required';
            }
        }
        else if(self.ImageFile && self.isEditPopup && self.bannerUserTypes.length!=0){
          self.isSpinner = true;
          var bannerObject = {
              updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
              userType:self.bannerUserTypes,
          }
          updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.BANNERMANAGEMENT,self.bannersList[self.editId].id,bannerObject,(res) => {
            self.$root.$emit('showToastMessage','Banner updated successfully.','success');
           
            self.$nextTick(() => {
              self.$bvModal.hide("modal-banner");
               self.closeFire();
              self.isSpinner = false;
            });
          })
        }
        else if(!self.ImageFile && self.isBannerImage && self.isEditPopup && self.bannerUserTypes.length!=0){
          self.isSpinner = true;
          storageFirebase.uploadImageFiles(dbCollections.BANNERMANAGEMENTSTORAGE,self.bannerImageFile.name,self.bannerImageFile,(res) => {
            var bannerObject = {
                updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                userType:self.bannerUserTypes,
                fileName:self.bannerImageFile.name,
                fileSizeInKB : self.bytesToSize(self.bannerImageFile.size),
                uploadFile : res.imageUrl,
            }
            updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.BANNERMANAGEMENT,self.bannersList[self.editId].id,bannerObject,(res) => {
              self.$root.$emit('showToastMessage','Banner updated successfully.','success');
             
              self.$nextTick(() => {
                self.$bvModal.hide("modal-banner");
                 self.closeFire();
                self.isSpinner = false;
              });
            })
          })
        }
        else
        {
         self.isSpinner = false;
        }
      });
    },
    changeChild(){
    },
    closeFire(){
      this.$bvModal.hide("modal-banner");
      this.bannerUserTypes = [];
      this.bannerImageFile = {};
      this.isBannerImage = true;
      this.bannerRequiredImafge = '';
      this.tempArray = [];
      this.OpenAddBanner();
    },
    OpenAddBanner(){
      this.isEditPopup = false;
      this.isEditClass = false;
      this.ImageFile = '';
      this.bannerDynemicTitle = 'Upload New Banner',
      this.bannerDynemicButton = 'Save'
    },
    bytesToSize(bytes) {
      var sizes = [ 'KB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[0];
    },
    notificationresetModal() {
      this.name = "";
      this.nameState = null;
      this.selectedNotificationUser = "all";
      this.notificationMessage = '';
    },
    notificationhandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.createNotification();
    },
    createNotification() {
      var self = this;
      self.$refs.notificationForm.validate().then((valid) => {
        try {
          var self = this;
          if (
            valid &&
            self.selectedNotificationUser&&
            self.notificationMessage
          ) {
             self.isSpinner = true;
            var notificationManagementObj = {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              userType: self.selectedNotificationUser,
              description: self.notificationMessage,
              isDeleted: false,
            };
            insertFirebase.addDataToRootCollection(
              dbCollections.NOTIFICATIONMANAGEMENT,
              notificationManagementObj,
              (res) => {
                if (res.id) {
                  var resObject = {
                    id: res.id,
                  };
                updateFirebase.updateRootCollectionDataByDocId(
                  dbCollections.NOTIFICATIONMANAGEMENT,
                  res.id,
                  resObject,
                  (res) => {
                    sendNotification({
                        isOnlyTutor: (notificationManagementObj.userType === "tutor"),
                        message: notificationManagementObj.description
                    },(resNoti) => {
                        if(resNoti.status) {
                            self.$root.$emit(
                              "showToastMessage",
                              "Notification sent successfully.",
                              "success"
                            );
                        } else {
                            self.$root.$emit(
                              "showToastMessage",
                              "Notification not sent successfully.",
                              "danger"
                            );
                        }
                    })
                    self.closeFire();
                self.$nextTick(() => {
                  self.$bvModal.hide("modal-notification");
                  self.isSpinner = false;
                });
                  }
                );
                }
              }
            );
          }
        } catch (error) {
          console.error("error", error);
        }
      });
    },
    notificationhandleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.submittedNames.push(this.name);
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    bookingrejectresetModal() {
      this.name = "";
      this.nameState = null;
    },
    bookingrejecthandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    bookingrejecthandleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.submittedNames.push(this.name);
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    cancelbookingresetModal() {
      this.name = "";
      this.RadioSelected = 'Not interested in this Session'
      this.nameState = null;
    },
    cancelbookinghandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.cancelbookinghandleSubmit(bvModalEvt);  
    },
    cancelbookinghandleSubmit() {
       this.$refs.formCancel.validate().then((valid)=>{
        if(valid){
          this.$root.$emit('startButtonDisabled')
          this.$root.$emit('BookingCancelUpComingData',this.name,this.RadioSelected);
          this.$root.$emit('openCancelBookingModel',this.name,this.RadioSelected);
          this.$bvModal.hide("modal-cancel-booking");
        }
      })
    },
    bannerCreateDate(bannerCreateDate){
      var self = this;
      var bannerDate = new Date(bannerCreateDate.seconds*1000).getDate();
      var bannerMonth = new Date(bannerCreateDate.seconds*1000).getMonth()+1;
      var bannerYear = new Date(bannerCreateDate.seconds*1000).getFullYear();
      var returnBannerDate = self.addZero(bannerDate)+'-'+self.addZero(bannerMonth)+'-'+bannerYear+' , '+ self.formatAMPM(new Date(bannerCreateDate.seconds*1000));
      return returnBannerDate;
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    addZero(value){
      if(value > 9){
        return value;
      }else{
        return '0' + value;
      }
    },
    showConfirm: function (index) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to confirm this booking request?",
          {
            title: "Confirm Booking Request",
            class: "test",
            okVariant: "primary",
            okTitle: "Confirm",
            cancelTitle: "Cancel",
            cancelVariant: "outline-primary",
            hideHeaderClose: false,
            centered: true,
            footerClass: "approve-footer",
            headerClass: "approve-header",
            bodyClass: "approve-body",
          }
        )
        .then((value) => {
          this.boxOne = value;
        });
    },
    showApprove: function (index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to Approve?", {
          title: "Approve",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if(value === true){
            this.$root.$emit('startSpinnerForActionPerformed');
            this.$root.$emit("calledAfterCancelActionPerform",index,1)
            try {
              const actionreasondetail = {
                cancelReasonStatus: Number(1),
                updatedAt: new Date()
              };
              updateFirebase.updateRootCollectionDataByDocId(
                dbCollections.BOOKING,
                index,
                actionreasondetail,
                (res) => {
                  let newArray = [];
                  this.cancelSessionArrayEmpty(newArray);
                  index = ''
                  this.usersessionId = ''
                  this.$root.$emit('stopSpinnerForActionPerformed');
                  this.$root.$emit(
                    "showToastMessage",
                    "Tutor reason approved.",
                    "success"
                  );
                  this.$router.push({ name: "cancellation-session-details" });
                }
              );
            } catch (error) {
              console.error("error",error)
              this.$root.$emit('stopSpinnerForActionPerformed');
            }
          }
        });
    },
    showGivewarning: function (index) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to Give Warning? ${this.totalNoOfWarningOfTutor!==0?`You had already given ${this.totalNoOfWarningOfTutor} warning before`:''}`,
          {
            title: "Give Warning",
            class: "test",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-primary",
            hideHeaderClose: false,
            centered: true,
            footerClass: "approve-footer",
            headerClass: "approve-header warning-header",
            bodyClass: "approve-body-warning",
          }
        )
        .then((value) => { //new
          if(value === true){
            this.$root.$emit('startSpinnerForActionPerformed')
            try {
              const actionreasondetail = {
                cancelReasonStatus: Number(3),
                updatedAt: new Date()
              };
              updateFirebase.updateRootCollectionDataByDocId(
                dbCollections.BOOKING,
                index,
                actionreasondetail,
                () => {
                  getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,this.usersessionId,(cancelDatas)=>{
                    let warnning = cancelDatas.data.warning ? cancelDatas.data.warning : 0
                    const actionreasondetai2 = {
                      warning: warnning+1,
                      updatedAt : new Date()
                    };
                    updateFirebase.updateRootCollectionDataByDocId(
                    dbCollections.USERS,
                    this.usersessionId,
                    actionreasondetai2,
                      () => {
                        let newArray = [];
                        functionForGiveWarning(this.usersessionId,"booking",this.getsettingList.warningResetDays).catch(error=>{
                          console.error("error",error)
                        })
                        this.cancelSessionArrayEmpty(newArray);
                        index = ''
                        this.userId = ''
                        this.totalNoOfWarningOfTutor = 0
                        this.totalWarnForSuspend = 0
                        this.$root.$emit('stopSpinnerForActionPerformed');
                        this.$root.$emit(
                          "showToastMessage",
                          "You gave warning to the tutor.",
                          "success"
                        );
                        this.$root.$emit("calledAfterCancelActionPerform",index,3)
                        onWarningGiveNotification({
                          tutorId :  this.usersessionId
                        }, () => {});
                        this.$router.push({ name: "cancellation-session-details" });
                      })
                  })         
                }
              );
            } catch (error) {
              console.error("error",error)
              this.$root.$emit('stopSpinnerForActionPerformed');
            }
          }
        });
    },
    showSuspendtutor: function (index) {
      var self= this;
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to Suspend Tutor?This will cancel all future tutor session and give the student a credit return before.", {
          title: "Suspend Tutor",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header suspend-header",
          bodyClass: "approve-body-warning",
        })
        .then((value) => { //new
          if(value === true){
            this.$root.$emit('suspendWaitProcessMessage')
            this.$root.$emit('startSpinnerForActionPerformed')
            try {
              const actionreasondetail = {
                cancelReasonStatus: Number(2),
                updatedAt: new Date()
              };
              updateFirebase.updateRootCollectionDataByDocId(
                dbCollections.BOOKING,
                index,
                actionreasondetail,
                () => {
                  this.$root.$emit("calledAfterCancelActionPerform",index,2)
                  const actionreasondetai2 = {
                    status: Number(2),
                    updatedAt : new Date(),
                    suspendUserTime: new Date()
                  };
                  updateFirebase.updateRootCollectionDataByDocId(
                  dbCollections.USERS,
                  this.usersessionId,
                  actionreasondetai2,
                  () => {
                    functionForSuspendtionAction(this.usersessionId).then(()=>{
                      functionForSuspendOrBlockChild(this.usersessionId,true,false,()=>{})
                      let newArray = [];
                      this.cancelSessionArrayEmpty(newArray);
                      index = ''
                      this.usersessionId = ''

                      self.$root.$emit(
                        "showToastMessage",
                        "Tutor suspended successfully.",
                        "success"
                      );
                      self.$router.push({ name: "cancellation-session-details" });
                      this.$root.$emit('stopSpinnerForActionPerformed');
                      this.$root.$emit('suspendWaitProcessMessageOff');
                    })
                    .catch((err)=>{
                      this.$root.$emit('stopSpinnerForActionPerformed');
                    }) 
                  })
                }
              );
            } catch (error) {
              console.error("error",error)
              this.$root.$emit('stopSpinnerForActionPerformed');
            }
          }
      
        });
    },
    editPopup(){
      
      var self = this;
      self.$root.$emit('editCategoryPopupOpen');
    },
    editSubCategoryPopupOpen(){
      var self = this;
      self.$root.$emit('editSubCatPopupOpen');
    },
    CancelEmailData(){
      var self = this;
      self.$root.$emit('cancelEmailData');
    },
    SavePlanData(){
      var self = this;
      self.$root.$emit('createPlanEvent');
    },
    EditPlanData(){
      var self = this;
      self.$root.$emit('editPlanEvent');
    },
    upcomingDetailPageSession(){
      this.$root.$on('upcomingDetailPagesCancel')
      this.isSpinner = true;
    },
    updateSwitchUser(){
      
      try{
        this.$root.$emit('changeLearner',this.tutorDetailSwitch);
        this.$root.$on('learnerValue',(value)=>{
            this.tutorDetailSwitch = !value;
        })
      }catch(error){
        console.error(error,"error")
      }
    }
  },  
};
</script>
